import {useListView} from '../../core/ListViewProvider'
import {GamesListToolbar} from './GameListToolbar'
import {GamesListGrouping} from './GamesListGrouping'
import {GamesListSearchComponent} from './GamesListSearchComponent'

const GamesListHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header py-6'>
      <GamesListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <GamesListGrouping /> : <GamesListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {GamesListHeader}

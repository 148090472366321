/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { Game } from '../../../../../_core/models/Game'

type Props = {
  game: Game
}

const GameInfoCell: FC<Props> = ({ game }) => (
  <div className='d-flex align-items-center'>

    <Link to={`/games/${game._id}`} className='btn btn-icon btn-bg-light btn-active-color-primary me-3'>
      <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-1' />
    </Link>

    <div className='d-flex flex-column'>
      <Link
        className='text-gray-800 text-hover-primary mb-1'
        to={`/games/${game._id}`}
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss='click'
        data-bs-placement='right'
      >
        {game.name}
      </Link>
      <span>{game.platform}</span>
    </div>
  </div>
)

export { GameInfoCell }

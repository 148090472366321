import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  initialListView,
  ListViewContextProps,
  groupingOnSelectAll,
  WithChildren,
} from '../../../../../_metronic/helpers'
import { Game } from '../../../../_core/models/Game'
import { Player } from '../../../../_core/models/Player'
import { Tournament } from '../../../../_core/models/Tournament'
import { useQueryRequest } from './QueryRequestProvider'
import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'

type CustomContextProps = {
  tournament?: Tournament
  players?: Player[]
} & ListViewContextProps

type Props = {
  tournament?: Tournament
  game?: Game
}

function calculatedPlayers(props: Props): Player[] {
  
  const players: Player[] = []
  props.tournament?.players?.forEach(player => {
    const temp = props.game?.players?.find(p => p._id === player)
    if(temp) {
      players.push(temp)
    }
  })

  return players
}



const ListViewContext = createContext<CustomContextProps>(initialListView)

const ListViewProvider: FC<WithChildren & Props> = ({children, game}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
  const {isLoading} = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])
  const [itemIdForDelete, setItemIdForDelete] = useState<ID>(initialListView.itemIdForDelete)
  const { tournament } = useQueryRequest()

  const columns = useMemo(() => calculatedPlayers({game, tournament}), [game, tournament])
  const players = useMemo(() => calculatedPlayers({game, tournament}), [game, tournament])
  
  return (
    <ListViewContext.Provider
      value={{
        players,
        tournament,
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
        itemIdForDelete,
        setItemIdForDelete
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}

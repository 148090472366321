import { useEffect, useRef, useState } from 'react'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { initialQueryState, KTSVG } from '../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import Flatpickr from 'react-flatpickr'

const GamesListFilter = () => {
  const { updateState } = useQueryRequest()
  const { isLoading } = useQueryResponse()

  const [name, setName] = useState<string | undefined>('')
  const [updatedAtDates, setUpdatedAtDates] = useState<Date[]>([])
  const [createdAtDates, setCreatedAtDates] = useState<Date[]>([]);

  const fpCreatedAtDates = useRef<Flatpickr>(null);
  const fpLastLogin = useRef<Flatpickr>(null);

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const clearCreatedAtDateRange = () => {
    if (!fpCreatedAtDates?.current?.flatpickr) return;
    setCreatedAtDates([])
    fpCreatedAtDates.current.flatpickr.clear()
  }

  const clearUpdatedAtDateRange = () => {
    if (!fpLastLogin?.current?.flatpickr) return;
    setUpdatedAtDates([])
    fpLastLogin.current.flatpickr.clear()
  }

  const resetData = () => {
    clearCreatedAtDateRange()
    clearUpdatedAtDateRange()
    setName('')
    updateState({ filter: undefined, ...initialQueryState })
  }

  const filterData = () => {

    let created_at_from = undefined;
    let created_at_to = undefined;
    let updated_at_from = undefined;
    let updated_at_to = undefined;

    if (createdAtDates.length === 2) {
      createdAtDates[0].setHours(0, 0, 0, 0)
      createdAtDates[1].setHours(23, 59, 59, 999)
      created_at_from = createdAtDates[0]
      created_at_to = createdAtDates[1]
    }

    if (updatedAtDates.length === 2) {
      updatedAtDates[0].setHours(0, 0, 0, 0)
      updatedAtDates[1].setHours(23, 59, 59, 999)
      updated_at_from = updatedAtDates[0]
      updated_at_to = updatedAtDates[1]
    }

    updateState({
      filter: {
        name,
        createdAt: { from: created_at_from, until: created_at_to },
        updatedAt: { from: updated_at_from, until: updated_at_to }
      },
      ...initialQueryState,
    })
  }

  const today = new Date();

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3 rotate'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-2 rotate-180 me-0 ms-4' />
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-game-table-filter='form'>

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Name:</label>
            <input
              type="text"
              className="form-control form-control-solid"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Created At:</label>
            <div className="input-group">
              <Flatpickr
                ref={fpCreatedAtDates}
                options={{
                  mode: "range",
                  maxDate: today,
                }}
                value={createdAtDates}
                className="form-control form-control-solid rounded rounded-end-0"
                onChange={(dates, str) => {
                  setCreatedAtDates(dates)
                }}
              />
              <button onClick={clearCreatedAtDateRange} className="btn btn-icon btn-light" id="kt_ecommerce_sales_flatpickr_clear">

                <span className="svg-icon svg-icon-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor"></rect>
                    <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor"></rect>
                  </svg>
                </span>

              </button>
            </div>
          </div>
          {/* end::Input group */}



          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Last Update:</label>
            <div className="input-group">
              <Flatpickr
                ref={fpLastLogin}
                options={{
                  mode: "range",
                  maxDate: today,
                }}
                value={updatedAtDates}
                className="form-control form-control-solid rounded rounded-end-0"
                onChange={(dates, str) => {
                  setUpdatedAtDates(dates)
                }}
              />
              <button onClick={clearUpdatedAtDateRange} className="btn btn-icon btn-light" id="kt_ecommerce_sales_flatpickr_clear">

                <span className="svg-icon svg-icon-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor"></rect>
                    <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor"></rect>
                  </svg>
                </span>

              </button>
            </div>
          </div>
          {/* end::Input group */}


          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-game-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-game-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { GamesListFilter }

import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../_metronic/helpers'
import { Player } from '../models/Player'

const API_URL = `${process.env.REACT_APP_API_URL}/players`

const getPlayerByIdOrEmail = (param: string): Promise<Player | undefined> => {
  return axios
    //.get(`${GET_USERS_URL}?${query}`)
    .get(`${API_URL}/${param}/find`)
    .then((response: AxiosResponse<Response<Player>>) => response.data)
    .then((response: Response<Player>) => response.data)
}

const getPlayers = (query: string): Promise<Response<Array<Player>>> => {
  return axios
    //.get(`${GET_USERS_URL}?${query}`)
    .get(`${API_URL}?${query}`)
    .then((d: AxiosResponse<Response<Array<Player>>>) => d.data)
}

const getPlayerById = (id: ID): Promise<Player | undefined> => {
  return axios
    .get(`${API_URL}/${id}`)
    .then((response: AxiosResponse<Response<Player>>) => response.data)
    .then((response: Response<Player>) => response.data)
}

const createPlayer = (player: Player): Promise<Player | undefined> => {
  return axios
    .post(API_URL, player)
    .then((response: AxiosResponse<Response<Player>>) => response.data)
    .then((response: Response<Player>) => response.data)
}

const updatePlayer = (player: Player): Promise<Player | undefined> => {
  return axios
    .patch(`${API_URL}/${player._id}`, player)
    .then((response: AxiosResponse<Response<Player>>) => response.data)
    .then((response: Response<Player>) => response.data)
}

const deletePlayer = (playerId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${playerId}`).then(() => {})
}

const deleteSelectedPlayers = (playerIds: Array<ID>): Promise<void> => {
  const requests = playerIds.map((id) => axios.delete(`${API_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getPlayerByIdOrEmail, getPlayers, deletePlayer, deleteSelectedPlayers, getPlayerById, createPlayer, updatePlayer}

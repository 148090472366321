import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {GamesListHeader} from './components/header/GamesListHeader'
import {GamesTable} from './table/GamesTable'
import {GameEditModal} from './game-edit-modal/GameEditModal'
import {KTCard} from '../../../../_metronic/helpers'
import { GamesListPagination } from './components/pagination/GamesListPagination'
import { GamesDeleteModal } from './games-delete-modal/GamesDeleteModal'

const GamesList = () => {
  const {itemIdForUpdate, itemIdForDelete} = useListView()
  return (
    <>
      <KTCard className='h-md-100'>
        <GamesListHeader />
        <GamesTable />
        <GamesListPagination />
      </KTCard>
      {itemIdForUpdate !== undefined && <GameEditModal />}
      {itemIdForDelete !== undefined && <GamesDeleteModal />}
    </>
  )
}

const GamesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <GamesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {GamesListWrapper}

import { useQuery } from 'react-query'
import { GameEditModalForm } from './GameEditModalForm'
import { isNotEmpty } from '../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { getGameById } from '../../../../_core/requests/games'
import { QUERIES } from '../../../../_core/consts'

const GameEditModalFormWrapper = () => {
  const { itemIdForUpdate, setItemIdForUpdate } = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: game,
    error,
  } = useQuery(
    `${QUERIES.GAMES}-game-${itemIdForUpdate}`,
    () => {
      return getGameById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )


  if (isLoading) {
    return (<div className="d-flex flex-column flex-center">
      <div className="spinner-border text-primary w-40px h-40px" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <div className='mt-3'>Loading...</div>
    </div>)
  }

  if (!itemIdForUpdate) {
    return <GameEditModalForm isGameLoading={isLoading} game={{ _id: undefined }} />
  }

  if (!isLoading && !error && game) {
    return <GameEditModalForm isGameLoading={isLoading} game={game} />
  }

  return null
}

export { GameEditModalFormWrapper }
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { AsideMenu } from './AsideMenu'
import { ModalTypes, useModalsManagement } from '../../core/ModalsManagement'

const AsideDefault: FC = () => {
  const { classes } = useLayout()
  return (
    <div
      id='kt_aside'
      className={clsx('aside bg-primary', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width='auto'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      {/* begin::Logo */}
      <div
        className='aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-8'
        id='kt_aside_logo'
      >
        <Link to='/dashboard'>
          <img src={toAbsoluteUrl('/media/logos/default-light.svg')} alt='logo' className="h-55px" />
        </Link>
      </div>
      {/* end::Logo */}

      {/* begin::Nav */}
      <div
        className='asaside-nav d-flex flex-column align-lg-center flex-column-fluid w-100 pt-5 pt-lg-0'
        id='kt_aside_nav'
      >
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Nav */}

      {/* begin::Footer */}
      <div
        className='aside-footer d-flex flex-column align-items-center flex-column-auto'
        id='kt_aside_footer'
      >
        {/* begin::Menu */}
        <div className='mb-7'>
          <button
            type='button'
            className='btn btm-sm btn-icon btn-color-white btn-active-color-primary btn-active-light'
            data-kt-menu-trigger='click'
            //data-kt-menu-overflow='true'
            data-kt-menu-placement='top-start'
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            data-bs-dismiss='click'
            title='Quick actions'

            data-kt-menu-attach='parent'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen008.svg'
              className='svg-icon-2 svg-icon-lg-1'
            />
          </button>
          <Dropdown />
        </div>
        {/* end::Menu */}
      </div>
      {/* end::Footer */}
    </div>
  )
}

const Dropdown: FC = () => {

  const { showModal } = useModalsManagement()

  const showCreateGameModal = () => {
    showModal(ModalTypes.CREATE_GAME_MODAL);
  };

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>Quick Actions</div>
      </div>

      <div className='separator mb-3 opacity-75'></div>

      <div className='menu-item px-3 d-grid'>
        <button type='button' onClick={showCreateGameModal} className='menu-link btn btn-sm fw-bold p-3'>
          New Game
        </button>
      </div>

      <div className='menu-item px-3'>
        <a href='#' className='menu-link p-3'>
          New Player
        </a>
      </div>

      <div className='menu-item px-3'>
        <a href='#' className='menu-link p-3'>
          New Customer
        </a>
      </div>

      <div
        className='menu-item px-3'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='right-start'
        data-kt-menu-flip='left-start, top'
      >
        <a href='#' className='menu-link px-3'>
          <span className='menu-title'>New Group</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-3'>
              Admin Group
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-3'>
              Staff Group
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-3'>
              Member Group
            </a>
          </div>
        </div>
      </div>

      <div className='menu-item px-3'>
        <a href='#' className='menu-link px-3'>
          New Contact
        </a>
      </div>

      <div className='separator mt-3 opacity-75'></div>

      <div className='menu-item px-3'>
        <div className='menu-content px-3 py-3'>
          <a className='btn btn-primary  btn-sm px-4' href='#'>
            Generate Reports
          </a>
        </div>
      </div>
    </div>
  )
}

export { AsideDefault }

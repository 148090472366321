import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../_metronic/helpers'
import { Game, Standing } from '../models/Game'
import { Player } from '../models/Player'

const API_URL = `${process.env.REACT_APP_API_URL}/games`

const getGames = (query: string): Promise<Response<Array<Game>>> => {
  return axios
    //.get(`${GET_USERS_URL}?${query}`)
    .get(`${API_URL}?${query}`)
    .then((d: AxiosResponse<Response<Array<Game>>>) => d.data)
}

const getGameById = (id: ID): Promise<Game | undefined> => {
  return axios
    .get(`${API_URL}/${id}`)
    .then((response: AxiosResponse<Response<Game>>) => response.data)
    .then((response: Response<Game>) => response.data)
}

const createGame = (game: Game): Promise<Game | undefined> => {
  return axios
    .post(API_URL, game)
    .then((response: AxiosResponse<Response<Game>>) => response.data)
    .then((response: Response<Game>) => response.data)
}

const updateGame = (game: Game): Promise<Game | undefined> => {
  return axios
    .patch(`${API_URL}/${game._id}`, game)
    .then((response: AxiosResponse<Response<Game>>) => response.data)
    .then((response: Response<Game>) => response.data)
}

const deleteGame = (gameId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${gameId}`).then(() => {})
}

const deleteSelectedGames = (gameIds: Array<ID>): Promise<void> => {
  const requests = gameIds.map((id) => axios.delete(`${API_URL}/${id}`))
  return axios.all(requests).then(() => {})
}


const attachPlayers = (id: ID, players: ID[]): Promise<Player | undefined> => {
  return axios
    .patch(`${API_URL}/${id}/players`, {players})
    .then((response: AxiosResponse<Response<Player>>) => response.data)
    .then((response: Response<Player>) => response.data)
}

const getGameWithPlayersByGameId = (id: ID): Promise<Game | undefined> => {
  return axios
    .get(`${API_URL}/${id}/players`)
    .then((response: AxiosResponse<Response<Game>>) => response.data)
    .then((response: Response<Game>) => response.data)
}

const getStandings = (id: ID, query: string): Promise<Response<Array<Standing>>> => {
  return axios
    //.get(`${GET_USERS_URL}?${query}`)
    .get(`${API_URL}/${id}/overview?${query}`)
    .then((d: AxiosResponse<Response<Array<Standing>>>) => d.data)
}

export {getStandings, attachPlayers, getGameWithPlayersByGameId, getGames, deleteGame, deleteSelectedGames, getGameById, createGame, updateGame}

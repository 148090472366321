import { FC, useState, createContext, useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import {
  QueryState,
  QueryRequestContextProps,
  initialQueryRequest,
  WithChildren,
  ID,
  Response
} from '../../../../../_metronic/helpers'
import { QUERIES } from '../../../../_core/consts'
import { Tournament } from '../../../../_core/models/Tournament'
import { getLastTournamentPlayers } from '../../../../_core/requests/user'

type ContextProps = {
  tournament?: Tournament
  reload?: () => void
} & QueryRequestContextProps

const QueryRequestContext = createContext<ContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<WithChildren & { tournament?: Tournament, reload?: () => void }> = ({ children, tournament, reload }) => {
  const [state, setState] = useState<QueryState>({ ...initialQueryRequest.state, filter: { game: tournament?.game, tournament: tournament?._id } })

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = { ...state, ...updates } as QueryState
    setState(updatedState)
  }

  useEffect(() => {

    updateState({filter: { game: tournament?.game, tournament: tournament?._id }})
    
  }, [tournament, reload])

  return (
    <QueryRequestContext.Provider value={{ state, updateState, tournament, reload }}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export { QueryRequestProvider, useQueryRequest }

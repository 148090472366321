import { FC } from 'react'
import { FieldProps } from 'formik'
import AsyncSelect from 'react-select/async'
import { getPlatforms } from '../../../requests/platforms'


export interface Option {
    readonly value: string;
    readonly label: string;
    readonly color: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
}

const loadOptions = (
    inputValue: string,
    callback: (options: Option[]) => void
) => {

    getPlatforms('page=1&items_per_page=250')
        .then(response => callback(response.data?.map(platform => ({ value: platform.name, label: platform.name })) as Option[]))

};

const SelectPlatform: FC<FieldProps> = ({ field, form, ...props }) => {

    return (
        <AsyncSelect
            defaultInputValue={field.value}
            //{...field}
            {...props}
            onChange={(option => form.getFieldHelpers('platform').setValue(option?.value))}
            //onChange={(option => formikHelper.setValue(option?.value) )}
            onBlur={field.onBlur}
            placeholder=""
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderWidth: 0
                    //borderColor: state.isFocused ? 'grey' : 'red',
                }),
            }}
            theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                spacing: {
                    ...theme.spacing,
                    controlHeight: 40,
                },
                colors: {
                    ...theme.colors,
                    //text: 'orangered',
                    //primary25: 'hotpink',
                    neutral0: '#f5f8fa',
                },
            })}
        />
    );
}



export default SelectPlatform;

import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import { PageTitle } from '../../_core/layout/core'
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget10,
  MixedWidget8,
  MixedWidget5,
  MixedWidget3,
} from '../../../_metronic/partials/widgets'
import { GamesListWrapper } from '../../modules/games/games-list/GamesList'
import { CompetitionsWrapper } from './competitions/Competitions'
import { PlayerGraphStatsWidget } from '../../_core/partials/widgets/player/statics/PlayerGraphStatsWidget'

const DashboardPage = () => {
  /*useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])*/

  return (
    <>
    {/* begin::Row  */}
    <div className='row g-5 g-xl-8 mb-5 mb-xl-10'>
        {/* begin::Col  */}
        <div className='col-xxl-4'>
          <PlayerGraphStatsWidget className='h-md-100' chartColor='primary' chartHeight='150px' />
        </div>
        {/* end::Col  */}
        {/* begin::Col  */}
        <div className='col-xxl-8'>
          <GamesListWrapper />
        </div>
        {/* end::Col  */}
      </div>
      {/* end::Row  */}

      {/* begin::Row  */}
      <div className='row gy-0 gx-5 gx-xl-8'>
        {/* begin::Col  */}
        <div className='col'>
        <CompetitionsWrapper />
        </div>
        {/* end::Col  */}
      </div>
      {/* end::Row  */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}

/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useQueryResponseLoading, useQueryResponsePagination } from '../../core/QueryResponseProvider'
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { getPages } from './TablePaginationHelpers'

const CompetitionsListPagination = () => {
  const pagination = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()
  const { updateState } = useQueryRequest()
  const updatePage = (page: number | null) => {
    if (!page || isLoading || pagination.page === page) {
      return
    }

    updateState({ page, items_per_page: pagination.items_per_page || 10 })
  }

  const updatePageSize = (items_per_page: number | null) => {
    if (!items_per_page || isLoading || pagination.items_per_page === items_per_page) {
      return
    }

    updateState({ items_per_page })
  }

  const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "30", value: 30 },
    { text: "50", value: 50 },
    { text: "100", value: 100 }
  ]

  if (!pagination.total || pagination.total <= 0) return (<></>)

  return (
    <div className='card-footer'>
      <div className='row'>
        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
          {pagination.last_page && pagination.last_page > 1 && (
            <div id='kt_table_competitions_paginate'>
              <ul className='pagination'>

                <li className={clsx('page-item', { disabled: isLoading })}>
                  <a
                    onClick={() => updatePage(1)}
                    className='page-link'
                    style={{ cursor: 'pointer' }}
                  >
                    <KTSVG path="/media/icons/duotune/arrows/arr021.svg" />
                  </a>
                </li>

                <li className={clsx('page-item previous', { disabled: isLoading })}>
                  <a
                    onClick={() => updatePage(pagination.page - 1)}
                    className="page-link"
                    style={{ cursor: 'pointer' }}
                  >
                    <i className="previous" />
                  </a>
                </li>

                {getPages(pagination.page, pagination.last_page, pagination.items_per_page).map((page) => (
                  <li key={page}
                    className={clsx('page-item', {
                      active: pagination.page === page,
                      disabled: isLoading,
                    })}
                  >
                    <a
                      onClick={() => updatePage(page)}
                      className='page-link'
                      style={{ cursor: 'pointer' }}
                    >
                      {page}
                    </a>
                  </li>
                ))}

                <li className={clsx('page-item next', { disabled: isLoading })}>
                  <a
                    onClick={() => updatePage(pagination.page + 1)}
                    className="page-link"
                    style={{ cursor: 'pointer' }}
                  >
                    <i className="next"></i>
                  </a>
                </li>

                <li className={clsx('page-item next', { disabled: isLoading })}>
                  <a
                    onClick={() => updatePage(pagination.last_page || null)}
                    className="page-link"
                    style={{ cursor: 'pointer' }}
                  >
                    <KTSVG path="/media/icons/duotune/arrows/arr024.svg" />
                  </a>
                </li>

              </ul>
            </div>
          )}
        </div>
        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
          <div className='w-75px me-5'>
            <select
              disabled={pagination.total === 0 || !pagination.total}
              data-control='select2'
              data-hide-search='true'
              className={`form-select form-select-white form-select-sm bg-light font-weight-bold border-0 ${pagination.total === 0 && "disabled"}`}
              defaultValue={pagination.items_per_page}

              onChange={(e) => updatePageSize(parseInt(e.target.value))}
            >
              {sizePerPageList.map(option => {
                return (
                  <option
                    key={option.text}
                    value={option.value}
                  >
                    {option.text}
                  </option>
                );
              })}
            </select>
          </div>
          <span className="react-bootstrap-table-pagination-total">Showing rows {pagination.from} to {pagination.to} of {pagination.total}</span>
        </div>
      </div>
    </div>
  )
}

export { CompetitionsListPagination }

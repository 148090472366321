import { KTSVG } from '../../../../../../_metronic/helpers'
import { ModalTypes, useModalsManagement } from '../../../../../_core/layout/core/ModalsManagement'
import { GamesListFilter } from './GamesListFilter'

const GamesListToolbar = () => {

  const { showModal } = useModalsManagement()
  const showCreateGameModal = () => {
    showModal(ModalTypes.CREATE_GAME_MODAL);
  };

  return (
    <div className='d-flex justify-content-end' data-kt-game-table-toolbar='base'>
      <GamesListFilter />

      {/* begin::Export */}
      <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button>
      {/* end::Export */}

      {/* begin::Add game */}
      <button
        type='button'
        onClick={showCreateGameModal}
        className='btn btn-primary'
      >
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Create
      </button>
      {/* end::Add game */}
    </div>
  )
}

export { GamesListToolbar }

import { FC } from 'react'
import { Game } from '../../../../../_core/models/Game'

type Props = {
  game: Game
}

const GamePlayersCell: FC<Props> = ({ game }) => (
  <div className='badge badge-light-primary fw-bolder'>{game.players?.length || 0}</div>
)

export { GamePlayersCell }

/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { useAuth } from '../../../../modules/auth'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSubMain } from './AsideMenuItemWithSubMain'

export function AsideMenuMain() {
  const intl = useIntl()
  const { currentUser } = useAuth()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-bar-chart-line'
        bsTitle={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        className='py-3'
      />
      <AsideMenuItem
        to='/builder'
        title='Layout Builder'
        bsTitle='Layout Builder'
        fontIcon='bi-gear'
        className='py-3'
      />

      <AsideMenuItem
        to='/games'
        title={intl.formatMessage({ id: 'MENU.GAMES' })}
        bsTitle={intl.formatMessage({ id: 'MENU.GAMES' })}
        fontIcon='bi-controller'
        className='py-3'
      />

      <AsideMenuItem
        to='/friends'
        title={intl.formatMessage({ id: 'MENU.FRIENDS' })}
        bsTitle={intl.formatMessage({ id: 'MENU.FRIENDS' })}
        fontIcon='bi-people'
        className='py-3'
      />

      <AsideMenuItemWithSubMain to='/lobby' title={intl.formatMessage({ id: 'MENU.LOBBY' })} fontIcon='bi-globe2' bsTitle={intl.formatMessage({ id: 'MENU.LOBBY' })}>
        <AsideMenuItem to='/lobby/games' title='Games' hasBullet={true} />
        <AsideMenuItem to='/lobby/players' title='Players' hasBullet={true} />
      </AsideMenuItemWithSubMain>

      <AsideMenuItem
        to='/platforms'
        title={intl.formatMessage({ id: 'MENU.PLATFORMS' })}
        bsTitle={intl.formatMessage({ id: 'MENU.PLATFORMS' })}
        fontIcon='bi-motherboard'
        className='py-3'
      />
      {currentUser?.isAdmin && (
        <AsideMenuItem
          to='/users'
          title={intl.formatMessage({ id: 'MENU.USERS' })}
          bsTitle={intl.formatMessage({ id: 'MENU.USERS' })}
          fontIcon='bi-person-video2'
          className='py-3'
        />
      )}


      <AsideMenuItem
        outside={true}
        to={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        title='User management'
        fontIcon='bi-card-text'
        bsTitle={`Changelog ${process.env.REACT_APP_VERSION}`}
        className='py-3'
      />
    </>
  )
}

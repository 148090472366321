/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'

type Data = {
  id: string
  name: string
  static: { totalPoints: number, totalGames: number, avg: number, victories: number }
}
type Props = {
  totalGames: number
  players: Data[]
}

const CompetitionCharts: React.FC<Props> = ({ players, totalGames }) => {

  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions(totalGames, players))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, players, totalGames])

  return (<div ref={chartRef} id='kt_charts_widget_6_chart' style={{ height: '250px' }}></div>)
}

export { CompetitionCharts }

function getChartOptions(totalGames: number, players: Data[]): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')

  const primaryColor = getCSSVariableValue('--kt-primary')
  const primaryLightColor = getCSSVariableValue('--kt-primary-light')
  const secondaryColor = getCSSVariableValue('--kt-secondary')
  const warningColor = getCSSVariableValue('--kt-warning')
  const infoColor = getCSSVariableValue('--kt-info')

  return {
    series: [
      {
        name: 'Total Games',
        type: 'bar',
        data: players.map(player => player.static.totalGames),
      },
      {
        name: 'Total Points',
        type: 'bar',
        data: players.map(player => player.static.totalPoints),
      },
      {
        name: 'Victories',
        type: 'bar',
        data: players.map(player => player.static.victories),
      },
      {
        name: 'Avg',
        type: 'bar',
        data: players.map(player => player.static.avg),
      },
    ],
    chart: {
      fontFamily: 'inherit',
      stacked: false,
      height: 250,
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: '22%',
        dataLabels: {
          orientation: 'vertical',
          position: 'center', // top, center, bottom
        },
      },
    },
    yaxis: [{
      show: true,
      seriesName: 'Total Games',
      max: totalGames,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: secondaryColor
      },
      labels: {
        style: {
          colors: secondaryColor,
          fontSize: '12px',
        },
        formatter: (value) => { return value ? value.toFixed(0) : '0' },
      },
      title: {
        text: "Total Games",
        style: {
          color: secondaryColor,
        }
      },
      tooltip: {
        enabled: false
      }
    },
    {
      show: false,
      seriesName: 'Total Points',
      opposite: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: infoColor
      },
      labels: {
        style: {
          colors: infoColor,
          fontSize: '12px',
        },
        formatter: (value) => { return value.toFixed(0) },
      },
      title: {
        text: "Total Points",
        style: {
          color: infoColor,
        }
      }
    },
    {
      show: false,
      seriesName: 'Victories',
      max: totalGames,
      opposite: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: primaryColor
      },
      labels: {
        style: {
          colors: primaryColor,
          fontSize: '12px',
        },
        formatter: (value) => { return value.toFixed(0) },
      },
      title: {
        text: "Victories",
        style: {
          color: primaryColor,
        }
      }
    },
    {
      show: false,
      seriesName: 'Avg',
      max: totalGames,
      opposite: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: warningColor
      },
      labels: {
        style: {
          colors: warningColor,
          fontSize: '12px',
        },
        //formatter: (value) => value.toFixed(2)
      },
      title: {
        text: "Avg",
        style: {
          color: warningColor,
        }
      }
    }],
    legend: {
      show: true
    },
    dataLabels: {
      enabled: true,
      //offsetY: -50
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: players.map(player => player.name),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      tooltip: {
        enabled: false
      }
    },

    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontSize: '12px',
      },

    },
    colors: [secondaryColor, infoColor, primaryColor, warningColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    }
  }
}
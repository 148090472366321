import { FC } from 'react'

type Props = {
  date: string
}

const DateCell: FC<Props> = ({ date }) => {

  const d = new Date(date);
  
  return (<div className="d-flex flex-column">
    <span className="text-gray-800 mb-1">{d.toDateString()}</span>
    <span>{d.toLocaleTimeString()}</span>
  </div>)
}

export { DateCell }

import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../_core/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const AppUsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const GamesPage = lazy(() => import('../modules/games/GamesPage'))

  const PlayersPage = lazy(() => import('../modules/players/PlayersPage'))
  const PlatformsPage = lazy(() => import('../modules/platfroms/PlatformsPage'))
  const FriendsPage = lazy(() => import('../modules/friends/FriendsPage'))
  const LobbyGamesPage = lazy(() => import('../modules/lobby/games/GamesPage'))
  const LobbyPlayersPage = lazy(() => import('../modules/lobby/players/PlayersPage'))


  const UsersPage = lazy(() => import('../modules/users/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='games/*'
          element={
            <SuspensedView>
              <GamesPage />
            </SuspensedView>
          }
        />
        <Route
          path='players/*'
          element={
            <SuspensedView>
              <PlayersPage />
            </SuspensedView>
          }
        />
        <Route
          path='friends/*'
          element={
            <SuspensedView>
              <FriendsPage />
            </SuspensedView>
          }
        />
        <Route
          path='lobby/games/*'
          element={
            <SuspensedView>
              <LobbyGamesPage />
            </SuspensedView>
          }
        />
        <Route
          path='lobby/players/*'
          element={
            <SuspensedView>
              <LobbyPlayersPage />
            </SuspensedView>
          }
        />
        <Route
          path='platforms/*'
          element={
            <SuspensedView>
              <PlatformsPage />
            </SuspensedView>
          }
        />

        <Route
          path='users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <AppUsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }

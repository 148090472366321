import {Column} from 'react-table'
import {CompetitionInfoCell} from './CompetitionInfoCell'
import {CompetitionLastLoginCell} from './CompetitionLastLoginCell'
import {CompetitionActionsCell} from './CompetitionActionsCell'
import {CompetitionSelectionCell} from './CompetitionSelectionCell'
import {CompetitionCustomHeader} from './CompetitionCustomHeader'
import {CompetitionSelectionHeader} from './CompetitionSelectionHeader'
import { DateCell } from './DateCell'
import { Competition } from '../../../../../_core/models/Competition'
import { Player } from '../../../../../_core/models/Player'
import { KTSVG } from '../../../../../../_metronic/helpers'

const getColumns = (players: Player[]): ReadonlyArray<Column<any>> => {
  const columns: Array<Column<any>> = []

  players.forEach((player, idx) => {

    columns.push({
      Header: player.name,
      id: `${player._id}`,
      Cell: ({ ...props }) => {
        const points = props.data[props.row.index][player._id as string]
        return (<>
          {points} {props.data[props.row.index].winner === player._id && (<KTSVG path="/media/icons/duotune/general/gen020.svg" className="svg-icon-1 svg-icon-warning" />)}
        </>)
      }
    })
  })

  columns.push({
    Header: (props) => (
      <CompetitionCustomHeader tableProps={props} title='Created date' className='text-end min-w-100px' />
    ),
    Cell: ({ ...props }) => <DateCell date={props.data[props.row.index].createdAt} />,
    accessor: 'createdAt',
  })

  columns.push({
    Header: (props) => (
      <CompetitionCustomHeader tableProps={props} title='Actions' className='text-end min-w-50px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <CompetitionActionsCell id={props.data[props.row.index]._id} />,
  })

  return columns as ReadonlyArray<Column<any>>
}

export default getColumns

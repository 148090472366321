import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../_metronic/helpers'
import { Platform } from '../models/Platform'

const API_URL = `${process.env.REACT_APP_API_URL}/platforms`

const getPlatforms = (query: string): Promise<Response<Array<Platform>>> => {
  return axios
    //.get(`${GET_USERS_URL}?${query}`)
    .get(`${API_URL}?${query}`)
    .then((d: AxiosResponse<Response<Array<Platform>>>) => d.data)
}

const getPlatformById = (id: ID): Promise<Platform | undefined> => {
  return axios
    .get(`${API_URL}/${id}`)
    .then((response: AxiosResponse<Response<Platform>>) => response.data)
    .then((response: Response<Platform>) => response.data)
}

const createPlatform = (platform: Platform): Promise<Platform | undefined> => {
  return axios
    .post(API_URL, platform)
    .then((response: AxiosResponse<Response<Platform>>) => response.data)
    .then((response: Response<Platform>) => response.data)
}

const updatePlatform = (platform: Platform): Promise<Platform | undefined> => {
  return axios
    .patch(`${API_URL}/${platform._id}`, platform)
    .then((response: AxiosResponse<Response<Platform>>) => response.data)
    .then((response: Response<Platform>) => response.data)
}

const deletePlatform = (platformId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${platformId}`).then(() => {})
}

const deleteSelectedPlatforms = (platformIds: Array<ID>): Promise<void> => {
  const requests = platformIds.map((id) => axios.delete(`${API_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getPlatforms, deletePlatform, deleteSelectedPlatforms, getPlatformById, createPlatform, updatePlatform}
import { useMemo } from 'react'
import { useTable, Row, Column } from 'react-table'
import { CustomHeaderColumn } from './columns/CustomHeaderColumn'
import { CustomRow } from './columns/CustomRow'
import { useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination, useQueryResponseStatics } from '../core/QueryResponseProvider'
import getColumns from './columns/_columns'
import { Competition } from '../../../../_core/models/Competition'
import { CompetitionsListLoading } from '../components/loading/CompetitionsListLoading'
import { CompetitionsListPagination } from '../components/pagination/CompetitionsListPagination'
import { KTCardBody, KTSVG } from '../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { useAuth } from '../../../../modules/auth'
import { CompetitionCharts } from '../../../../common/widgets/charts/CompetitionCharts'
import { useQueryRequest } from '../core/QueryRequestProvider'

const CompetitionsTable = () => {
  const competitions = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const pagination = useQueryResponsePagination()
  const { players, tournament, setItemIdForUpdate } = useListView()
  const statics = useQueryResponseStatics()
  const { currentUser } = useAuth()
  const { reload } = useQueryRequest()

  const data = useMemo(() => competitions.map(competition => {
    const keys = competition.scores?.reduce((a, v) => ({ ...a, [v.player as string]: v.points }), {})
    return ({ ...competition, ...keys })
  }), [competitions])


  const columns = useMemo<ReadonlyArray<Column<any>>>(
    () => tournament?.players ? getColumns(tournament.players) : [],
    [tournament]
  )

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow, allColumns, headerGroups } = useTable({
    columns,
    data
  })

  const openAddCompetitionModal = () => {
    setItemIdForUpdate(null)
  }


  const visibleColumns = allColumns.filter(column => column.isVisible)

  const chartPlayers = useMemo(
    () => {
      if (!statics || !tournament?.players) return []

      return visibleColumns.filter(column => tournament?.players?.find(player => player._id === column.id))
        .map(column => {

          const found = statics.find((s: any) => s._id === column.id)
          return ({
            id: column.id,
            name: tournament?.players?.find(player => player._id === column.id)?.name || '',
            static: found ? found : { totalPoints: 0, totalGames: 0, avg: 0, victories: 0 }
          })
        })
    },
    [visibleColumns.length, tournament, statics]
  )

  return (<>
    {/*<CompetitionsListHeader columnsFilter={<ColumnsFilter />} />*/}
    <div className='card-header py-6'>
      <div className='card-title fw-bold'>
        Last Tournament Activity
      </div>
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end'>

          <div className='d-flex border border-1 rounded-1'>
            <div className='bg-light-primary p-3'>
              <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2 text-primary' />
            </div>

            {allColumns.filter(column => column.id !== 'selection' && column.id !== 'createdAt' && column.id !== 'actions').map(column => (
              <div key={column.id} className="form-check form-check-custom form-check-solid form-check-sm px-4 border-start">
                <input className="form-check-input" type="checkbox" {...column.getToggleHiddenProps()} />
                <label className="form-check-label">
                  {tournament?.players?.find(player => player._id === column.id)?.name}
                </label>
              </div>
            ))}
          </div>

          <button
            //disabled={isLoading}
            type='button'
            className='btn btn-light-primary ms-3'
            onClick={reload}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr029.svg' className='svg-icon-2' />
            Reload
          </button>

          {/* begin::Add competition */}
          <button type='button' className='btn btn-primary ms-3' onClick={openAddCompetitionModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add Competition
          </button>
          {/* end::Add competition */}

        </div>
      </div>
    </div>
    <KTCardBody className='p-0 border-bottom'>
      <CompetitionCharts players={chartPlayers} totalGames={pagination.total || 0} />
    </KTCardBody>
    <KTCardBody className='p-0'>

      <div className='table-responsive'>
        <table
          id='kt_table_competitions'
          className='table align-middle table-row-dashed fs-6 gs-10 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>

            {headerGroups.map(headerGroup => (
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0' {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            ))}


          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Competition>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} authPlayer={currentUser?.player._id} />
              })
            ) : (
              <tr>
                <td colSpan={2}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {isLoading && <CompetitionsListLoading />}
    </KTCardBody>
    <CompetitionsListPagination />
  </>)
}

export { CompetitionsTable }

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { useThemeMode } from '../../../../layout/theme-mode/ThemeModeProvider'
import { getCSSVariableValue } from '../../../../../../../_metronic/assets/ts/_utils'
//import { getStatics } from '../../_core/requests/user'
//import { Statics } from '../../_core/models/Competition'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { Stats } from '../../../../../models/Competition'


type Props = {
    data: Stats
}

const StatsList: React.FC<Props> = ({ data }) => {

    return (<>
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                    <i className="bi bi-controller fs-1"></i>
                </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                    <span className='fs-5 text-gray-800 fw-bold'>
                        Games
                    </span>
                    <div className='text-gray-400 fw-semibold fs-7'>Total Games</div>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                    <div className='fw-bold fs-5 text-gray-800 pe-1'>{data?.totalGames || 0}</div>
                </div>
                {/* end::Label */}
            </div>
            {/* end::Description */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                    <i className="bi bi-card-list fs-1"></i>
                </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                    <span className='fs-5 text-gray-800 fw-bold'>
                        Tournaments
                    </span>
                    <div className='text-gray-400 fw-semibold fs-7'>Total Tournaments</div>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                    <div className='fw-bold fs-5 text-gray-800 pe-1'>{data?.totalTournaments || 0}</div>
                </div>
                {/* end::Label */}
            </div>
            {/* end::Description */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                    <i className="bi bi-joystick fs-1"></i>
                </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                    <span className='fs-5 text-gray-800 fw-bold'>
                        Competitions
                    </span>
                    <div className='text-gray-400 fw-semibold fs-7'>Total Competitions</div>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                    <div className='fw-bold fs-5 text-gray-800 pe-1'>{data?.totalCompetitions || 0}</div>
                </div>
                {/* end::Label */}
            </div>
            {/* end::Description */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                    <KTSVG path='/media/icons/duotune/general/gen020.svg' className='svg-icon-1' />
                </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                    <span className='fs-5 text-gray-800 fw-bold'>
                        Victories
                    </span>
                    <div className='text-gray-400 fw-semibold fs-7'>Total Victories</div>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                    <div className='fw-bold fs-5 text-gray-800 pe-1'>{data?.totalVictories || 0}</div>
                </div>
                {/* end::Label */}
            </div>
            {/* end::Description */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                    <i className="bi bi-currency-exchange fs-1"></i>
                </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                    <span className='fs-5 text-gray-800 fw-bold'>
                        Points
                    </span>
                    <div className='text-gray-400 fw-semibold fs-7'>Total Points</div>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                    <div className='fw-bold fs-5 text-gray-800 pe-1'>{data?.totalPoints || 0}</div>
                </div>
                {/* end::Label */}
            </div>
            {/* end::Description */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                    <KTSVG path='/media/icons/duotune/ecommerce/ecm003.svg' className='svg-icon-1' />
                </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                    <span className='fs-5 text-gray-800 fw-bold'>
                        Points Average
                    </span>
                    <div className='text-gray-400 fw-semibold fs-7'> (Points / Competitions)</div>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                    <div className='fw-bold fs-5 text-gray-800 pe-1'>{data?.avg?.points || 0}</div>
                </div>
                {/* end::Label */}
            </div>
            {/* end::Description */}
        </div>
        {/* end::Item */}

        {/* begin::Item */}
        <div className='d-flex align-items-center'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                    <KTSVG path='/media/icons/duotune/ecommerce/ecm003.svg' className='svg-icon-1' />
                </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
                {/* begin::Title */}
                <div className='mb-1 pe-3 flex-grow-1'>
                    <span className='fs-5 text-gray-800 fw-bold'>
                        Victories Average
                    </span>
                    <div className='text-gray-400 fw-semibold fs-7'> (Victories / Competitions)</div>
                </div>
                {/* end::Title */}
                {/* begin::Label */}
                <div className='d-flex align-items-center'>
                    <div className='fw-bold fs-5 text-gray-800 pe-1'>{data?.avg?.victories || 0}%</div>
                </div>
                {/* end::Label */}
            </div>
            {/* end::Description */}
        </div>
        {/* end::Item */}

    </>)
}

export { StatsList }
import { FieldHelperProps, FieldInputProps } from 'formik';
import React, { FC } from 'react';

import AsyncSelect from 'react-select/async';
import { getPlatforms } from '../../../../_core/requests/platforms';

export interface Option {
    readonly value: string;
    readonly label: string;
    readonly color: string;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
}

const loadOptions = (
    inputValue: string,
    callback: (options: Option[]) => void
) => {

    getPlatforms('page=1&items_per_page=250')
        .then(response => callback(response.data?.map(platform => ({ value: platform.name, label: platform.name })) as Option[]))

};


type Props = {
    formikProps?: FieldInputProps<any>
    formikHelper: FieldHelperProps<any>
}

const SelectPlatform: FC<Props> = ({ formikProps, formikHelper }) => {
    return (
        <AsyncSelect
            defaultInputValue={formikProps?.value}
            onChange={(option => formikHelper.setValue(option?.value) )}
            onBlur={formikProps?.onBlur}
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            theme={(theme) => ({
                ...theme,
                borderRadius: 5,
                spacing:{
                    ...theme.spacing,
                    controlHeight: 40,
                },
                colors: {
                ...theme.colors,
                  //text: 'orangered',
                  //primary25: 'hotpink',
                  neutral0: '#f5f8fa',
                },
              })}
        />
    );
}



export default SelectPlatform;

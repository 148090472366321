const QUERIES = {
  GAME_OVERVIEW: 'game-overview',
  TOURNAMENTS: 'tournaments',
  PLAYERS: 'players',
  COMPETITIONS: 'competitions',
  CONSOLES: 'consoles',
  PLATFORMS: 'platforms',
  GAMES: 'games',
  USER: 'user'
}

export {QUERIES}

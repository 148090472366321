import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import { useLocation } from 'react-router-dom'
import { InviteUsers, UpgradePlan } from '../partials'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { ModalsManagementProvider } from './core/ModalsManagement'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ModalsManagementProvider>
        <div className='d-flex flex-column flex-root'>
          {/* begin::Page */}
          <div className='page d-flex flex-row flex-column-fluid'>
            <AsideDefault />
            {/* begin::Wrapper */}
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <HeaderWrapper />

              {/* begin::Content */}
              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <Content>
                  <Outlet />
                </Content>
              </div>
              {/* end::Content */}
           
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Page */}
        </div>

        {/* begin:: Modals */}

        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ModalsManagementProvider>
    </PageDataProvider>
  )
}

export { MasterLayout }

import { useState, useEffect } from "react";
import { Stats } from "../../../../models/Competition";
import { getStats } from "../../../../requests/user";

const useFetch = () => {
  const [data, setData] = useState<Stats | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    getStats().then((response) => { 
      setData(response.data || null)
      setLoading(false)
    })

  }, []);

  return {loading, data};
};

export { useFetch };
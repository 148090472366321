/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG } from '../../../../../../_metronic/helpers'
import { toast } from '../../../../../common/toast/ToastManager'
import { QUERIES } from '../../../../../_core/consts'
import { deleteGame } from '../../../../../_core/requests/games'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'

type Props = {
  id: ID
}

const GameActionsCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate, editableData } = useListView()
  const { query } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteGame(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.GAMES}-${query}`])

      toast.show({
        title: "Game deleted",
        content: `Game has been successfully deleted. `,
        duration: 6000,
        variant: 'success'
      })
    },
  })



  if (editableData.some(game => game._id === id)) {
    return (
      <>
        <a
          href='#'
          className='btn btn-icon btn-light btn-active-light-primary btn-sm'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >

          <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-5 m-0' />
        </a>
        {/* begin::Menu */}
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
          data-kt-menu='true'
        >
          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <Link to={`/games/${id}`} className='menu-link px-3'>
              View
            </Link>
          </div>
          {/* end::Menu item */}

          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={openEditModal}>
              Edit
            </a>
          </div>
          {/* end::Menu item */}

          {/* begin::Menu item */}
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-games-table-filter='delete_row'
              onClick={async () => await deleteItem.mutateAsync()}
            >
              Delete
            </a>
          </div>
          {/* end::Menu item */}

        </div>
        {/* end::Menu */}
      </>
    )
  } else {
    return (
      <Link to={`/games/${id}`} className='btn btn-icon btn-light btn-active-light-primary btn-sm'>
        <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-5 m-0' />
      </Link>
    )
  }

}

export { GameActionsCell }

import { FC, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isNotEmpty } from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import { useListView } from '../core/ListViewProvider'
import { GamesListLoading } from '../components/loading/GamesListLoading'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { toast } from '../../../../common/toast/ToastManager'
import SelectPlatform from './select'
import { Game } from '../../../../_core/models/Game'
import { createGame, updateGame } from '../../../../_core/requests/games'

type Props = {
  isGameLoading: boolean
  game: Game
}

const initialGame: Game = {
  name: '',
  platform: '',
}

const editGameSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  platform: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Platform is required'),
})

const GameEditModalForm: FC<Props> = ({ game, isGameLoading }) => {
  const { setItemIdForUpdate } = useListView()
  const { refetch } = useQueryResponse()

  const [gameForEdit] = useState<Game>({
    ...game,
    name: game.name || initialGame.name,
    platform: game.platform || initialGame.platform,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: gameForEdit,
    validationSchema: editGameSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values._id)) {
          await updateGame(values)
        } else {
          await createGame(values)
        }

        const action = isNotEmpty(values._id) ? 'updated' : 'created'
        toast.show({
          title: `Game ${action}`,
          content: `Game ${values.name} has been successfully ${action}. `,
          duration: 6000,
          variant: 'success'
        })

        setSubmitting(true)
        cancel(true)

      } catch (ex: any) {
        if (ex.response.status === 400) {
          Object.entries(ex.response.data.payload.errors).map(([key, val]) => setFieldError(key, val as string));
        }
      } finally {
        //setSubmitting(true)
        //cancel(true)

      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_game_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div className='d-flex flex-column'>

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Name'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                { 'is-invalid': formik.touched.name && formik.errors.name },
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isGameLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}



          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Platform</label>
            {/* end::Label */}

            {/* begin::Input */}
            <SelectPlatform formikHelper={formik.getFieldHelpers('platform')} formikProps={formik.getFieldProps('platform')}></SelectPlatform>

            {formik.touched.platform && formik.errors.platform && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.platform}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}



        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-games-modal-action='cancel'
            disabled={formik.isSubmitting || isGameLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-games-modal-action='submit'
            disabled={isGameLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isGameLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isGameLoading) && <GamesListLoading />}
    </>
  )
}

export { GameEditModalForm }

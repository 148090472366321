/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from 'react'
import { ID, KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Formik, Form, FormikValues, Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup'
import { StepperComponent } from '../../../../../_metronic/assets/ts/components'
import SelectPlatform from './SelectPlatform'
import clsx from 'clsx'
import { Player } from '../../../models/Player'
import { createPlayer, getPlayerByIdOrEmail } from '../../../requests/players'
import { attachPlayers, createGame } from '../../../requests/games'
import { Game } from '../../../models/Game'
import { toast } from '../../../../common/toast/ToastManager'
import { useNavigate } from 'react-router-dom'
import { useModalsManagement } from '../../../layout/core/ModalsManagement'

interface ICreateGame {
  name: string
  platform: string
  playerName: string
  playerNickname: string
  playerEmail: string
  player: Player | undefined
  attachPlayer: string
  players: [Player] | undefined
}

const inits: ICreateGame = {
  name: '',
  platform: '',
  playerName: '',
  playerNickname: '',
  playerEmail: '',
  player: { name: '', nickname: '', email: '' },
  attachPlayer: '',
  players: undefined,
}

const createGameSchema = [
  Yup.object({
    name: Yup.string().required().label('Name'),
    platform: Yup.string().required().label('Platform'),
    //category: Yup.string().required().label('Category'),
  }),
  Yup.object({
    player: Yup.object().shape({

      name: Yup.string().when(['nickname', 'email'], {
        is: (nickname: string, email: string) => nickname || email, // alternatively: (isBig, isSpecial) => isBig && isSpecial
        then: (schema) => schema.required().label('Full Name'),
        otherwise: (schema) => schema.optional(),
      }),
      nickname: Yup.string().when(['name', 'email'], {
        is: (name: string, email: string) => name || email, // alternatively: (isBig, isSpecial) => isBig && isSpecial
        then: (schema) => schema.required().label('Nickname'),
        otherwise: (schema) => schema.optional(),
      }),
      email: Yup.string().email().when(['nickname', 'name'], {
        is: (nickname: string, name: string) => nickname || name, // alternatively: (isBig, isSpecial) => isBig && isSpecial
        then: (schema) => schema.required().label('Email'),
        otherwise: (schema) => schema.optional(),
      }),

    }, [['nickname', 'email'], ['name', 'email'], ['nickname', 'name']]),

    //playerNickname: Yup.string().required().label('Nickname'),
    //playerEmail: Yup.string().required().label('Email'),
  }),
]

const CreateGame: FC = () => {
  const { hideModal } = useModalsManagement();

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createGameSchema[0])
  const [initValues] = useState<ICreateGame>(inits)
  const [playersActions, setPlayersActions] = useState<'Create' | 'Attach'>('Create')
  const btnAttachPlayerRef = useRef<HTMLButtonElement | null>(null)
  const navigate = useNavigate()
  //const closeModalRef = useRef<HTMLButtonElement | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createGameSchema[stepper.current.currentStepIndex - 1])
  }

  const saveGame = async (_game: Game, players?: Player[]) => {

    try {


      const game = await createGame(_game)
      if (game) {

        if (players && players.length > 0) {

          let playersIds: ID[] = []
          const playersToCreate = players.filter(player => !player._id)
          const playersToAttach = players.filter(player => player._id)
          playersToAttach.forEach(player => playersIds.push(player._id))

          if (playersToCreate.length > 0) {
            const promises = await Promise.all(playersToCreate.map(player => createPlayer(player)))
            promises.filter(player => player && player._id).forEach(player => playersIds.push(player?._id))
          }

          if (playersIds.length > 0) {
            await attachPlayers(game._id, playersIds)
          }

        }

        toast.show({
          title: `Game Created`,
          content: `Game ${game.name} has been successfully created. `,
          duration: 6000,
          variant: 'success'
        })

        hideModal()
        //closeModalRef.current?.click()
        navigate(`/games/${game._id}`, { replace: true })

      }

    } catch (error) {

    }


  }

  const submitStep = (values: ICreateGame, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    setCurrentSchema(createGameSchema[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {

      const game: Game = {
        name: values.name,
        platform: values.platform
      }

      saveGame(game, values.players)

      /*
      createGame(game).then(res => {

        if (values.players && values.players.length > 0) {

          const playersToCreate = values.players.filter(player => !player._id)
          const playersToAttach = values.players.filter(player => player._id)


          if (playersToCreate.length > 0) {

            Promise.all(playersToCreate.map(player => createPlayer(player)))
              .then(promises => {
                const players = promises.filter(player => player && player._id).map(player => player?._id)
                attachPlayers(res?._id, players)
              })

          }

          if (playersToAttach.length > 0) {
            attachPlayers(res?._id, playersToAttach.map(player => player?._id))
          }

        }


        toast.show({
          title: `Game Created`,
          content: `Game ${values.name} has been successfully created. `,
          duration: 6000,
          variant: 'success'
        })

        hideModal()
        //closeModalRef.current?.click()
        navigate(`/games/${res?._id}`, { replace: true })

      })*/




      //stepper.current.goto(1)
      //actions.resetForm()
    }
  }

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])


  const fetchPlayer = async (param: string, cb: (player: Player) => void, error: (msg: string) => void) => {
    try {
      // Disable indicator
      btnAttachPlayerRef.current?.setAttribute('data-kt-indicator', 'on');

      const player = await getPlayerByIdOrEmail(param);
      cb(player as Player)
    } catch (ex: any) {

      error(ex.response.data.payload.message)
    } finally {

      btnAttachPlayerRef.current?.removeAttribute("data-kt-indicator");
    }
  }

  return (<>
    <div className='modal fade show d-block' id='kt_modal_create_game' role="dialog" tabIndex={-1} aria-modal="true">
      <div className='modal-dialog modal-dialog-centered mw-900px'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2>Create Game</h2>

            <button className='btn btn-sm btn-icon btn-active-color-primary' onClick={hideModal}>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </button>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            <div
              ref={stepperRef}
              className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
              id='kt_modal_create_game_stepper'
            >
              <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
                <div className='stepper-nav ps-lg-10'>

                  {/* begin::Step 1*/}
                  <div className='stepper-item current' data-kt-stepper-element='nav'>
                    {/* begin::Wrapper*/}
                    <div className='stepper-wrapper'>
                      {/* begin::Icon*/}
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>1</span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Details</h3>

                        <div className='stepper-desc fw-semibold'>Name your game</div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    <div className='stepper-line h-40px'></div>
                    {/* end::Line*/}

                  </div>
                  {/* end::Step 1*/}

                  {/* begin::Step 2*/}
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    {/* begin::Wrapper*/}
                    <div className='stepper-wrapper'>
                      {/* begin::Icon*/}
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>2</span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Players</h3>
                        <div className='stepper-desc fw-semibold'>Define your game players</div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    <div className='stepper-line h-40px'></div>
                    {/* end::Line*/}
                  </div>
                  {/* end::Step 2*/}



                  {/* begin::Step 3*/}
                  <div className='stepper-item' data-kt-stepper-element='nav'>
                    {/* begin::Wrapper*/}
                    <div className='stepper-wrapper'>
                      {/* begin::Icon*/}
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>3</span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>Release</h3>
                        <div className='stepper-desc fw-semibold'>Review and Submit</div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}
                  </div>
                  {/* end::Step 3*/}


                </div>
              </div>

              <div className='flex-row-fluid px-lg-15'>
                <Formik
                  validationSchema={currentSchema}
                  initialValues={initValues}
                  onSubmit={submitStep}
                >
                  {(props) => (
                    <Form className='form' noValidate id='kt_modal_create_game_form'>

                      <div className='current' data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                              <span className='required'>Game Name</span>
                              <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Specify your game name'
                              ></i>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              name='name'
                              placeholder=''
                            />
                            <div className='text-danger'>
                              <ErrorMessage name='name' />
                            </div>
                          </div>

                          <div className='fv-row'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                              <span className='required'>Platform</span>
                              <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                title='Specify your platform'
                              ></i>
                            </label>


                            <Field
                              name="platform"
                              component={SelectPlatform}
                            />
                            <div className='text-danger'>
                              <ErrorMessage name='platform' />
                            </div>
                          </div>

                        </div>
                      </div>

                      <div data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='pb-10 pb-lg-15'>
                            <h2 className='fw-bolder text-dark'>{playersActions} Players</h2>

                            <div className='text-gray-400 fw-bold fs-6'>
                              <span className="text-primary fw-bolder">{playersActions}</span> {playersActions === 'Create' ? 'and attach' : 'an existing'} players.
                            </div>
                          </div>


                          <div className='nav-group nav-group-outline mx-auto mb-13' data-kt-buttons='true'>
                            <button
                              type='button'
                              className={clsx('btn btn-color-gray-400 w-50 btn-active btn-active-secondary px-6', { active: playersActions === 'Create' })}
                              onClick={() => setPlayersActions('Create')}
                              data-kt-plan='create'
                            >
                              Create
                            </button>
                            <button
                              type='button'
                              className={clsx('btn btn-color-gray-400 w-50 btn-active btn-active-secondary px-6', { active: playersActions === 'Attach' })}
                              onClick={() => setPlayersActions('Attach')}
                              data-kt-plan='attach'
                            >
                              Attach
                            </button>
                          </div>




                          <FieldArray
                            name="players"
                            render={arrayHelpers => (
                              <>
                                {/* Create Action */}
                                {playersActions === 'Create' && (<>
                                  <div className='d-flex flex-wrap gap-5 mb-10'>
                                    <div className='fv-row flex-md-root'>
                                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                        <span className='required'>Full Name</span>
                                        <i
                                          className='fas fa-exclamation-circle ms-2 fs-7'
                                          data-bs-toggle='tooltip'
                                          title='Specify player name'
                                        ></i>
                                      </label>

                                      <Field
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        name='player.name'
                                        placeholder=''
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name='player.name' />
                                      </div>
                                    </div>

                                    <div className='fv-row flex-md-root'>
                                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                        <span className='required'>Nickname</span>
                                        <i
                                          className='fas fa-exclamation-circle ms-2 fs-7'
                                          data-bs-toggle='tooltip'
                                          title='Specify player nickname'
                                        ></i>
                                      </label>

                                      <Field
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        name='player.nickname'
                                        placeholder=''
                                      />
                                      <div className='text-danger'>
                                        <ErrorMessage name='player.nickname' />
                                      </div>
                                    </div>
                                  </div>

                                  <div className='fv-row mb-10'>
                                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                      <span className='required'>Email</span>
                                      <i
                                        className='fas fa-exclamation-circle ms-2 fs-7'
                                        data-bs-toggle='tooltip'
                                        title='Specify player email'
                                      ></i>
                                    </label>

                                    <Field
                                      type='email'
                                      className='form-control form-control-lg form-control-solid'
                                      name='player.email'
                                      placeholder=''
                                    />
                                    <div className='text-danger'>
                                      <ErrorMessage name='player.email' />
                                    </div>
                                  </div>

                                  <div className='d-grid'>
                                    <button
                                      //ref={btnAttachPlayerRef}
                                      //disabled={props.values.player.length < 2}
                                      onClick={() => {
                                        if (props.values.player?.name && props.values.player?.nickname && props.values.player?.email && !props.errors.player) {
                                          const player: Player = {
                                            name: props.values.player.name,
                                            nickname: props.values.player.nickname,
                                            email: props.values.player.email
                                          }
                                          arrayHelpers.push(player)
                                          props.setFieldValue('player', { name: '', nickname: '', email: '' })
                                        } else {
                                          if (!props.values.player?.name) {
                                            props.setFieldError('player.name', 'Full Name is a required field')
                                          }
                                          if (!props.values.player?.nickname) {
                                            props.setFieldError('player.nickname', 'Nickname is a required field')
                                          }
                                          if (!props.values.player?.email) {
                                            props.setFieldError('player.email', 'Email is a required field')
                                          }
                                        }
                                      }}
                                      className="btn btn-primary btn-block"
                                      type="button"
                                      id="button-addon2"
                                    >
                                      Add New Player
                                    </button>
                                  </div>
                                </>)}
                                {/* End Create Action */}

                                {/* Attach Action */}
                                {playersActions === 'Attach' && (<>
                                  <label className='required fw-bold fs-6 mb-2'>Nickname / Email</label>
                                  <div className="input-group has-validation mb-7">

                                    <Field
                                      name="attachPlayer"
                                      type="text"
                                      className="form-control form-control-solid"
                                      placeholder="Nickname / Email"
                                      aria-label="Nickname / Email"
                                      aria-describedby="button-addon2" />
                                    <button
                                      ref={btnAttachPlayerRef}
                                      //disabled={props.values.player.length < 2}
                                      onClick={() => {
                                        if (props.values.attachPlayer && !props.errors.attachPlayer) {
                                          fetchPlayer(
                                            props.values.attachPlayer,
                                            (player: Player) => {
                                              if (props.values.players && props.values.players.length > 0) {
                                                const found = props.values.players.find(p => p._id === player._id);
                                                if (found) {
                                                  props.setFieldError('attachPlayer', 'Player is already exists')
                                                  return;
                                                }
                                              }

                                              arrayHelpers.push(player); props.setFieldValue('attachPlayer', '')
                                            },
                                            (msg: string) => props.setFieldError('attachPlayer', msg)
                                          )
                                        }
                                      }}
                                      className="btn btn-primary"
                                      type="button"
                                      id="button-addon2"
                                    >
                                      <span className="indicator-label">Attach</span>
                                      <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                      </span>
                                    </button>
                                    <ErrorMessage name='players' component="div" className="invalid-feedback" />
                                    <ErrorMessage name='attachPlayer' component="div" className="invalid-feedback" />
                                  </div>
                                </>)}
                                {/* End Attach Action */}


                                {props.values.players && props.values.players.length > 0 && (<div className='mt-10'>
                                  <div className='fs-6 fw-bold mb-2'>Players list</div>
                                  <div className='mh-300px scroll-y me-n7 pe-7'>
                                    {props.values.players.map((player, index) => {

                                      return (<div
                                        className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'
                                        key={index}
                                      >

                                        <Field className="d-none" name={`players.${index}`} />

                                        <div className='d-flex align-items-center'>
                                          <div className='symbol symbol-35px symbol-circle'>
                                            {player.avatar && (
                                              <div className='symbol-label'>
                                                <img src={toAbsoluteUrl(`/media/svg/avatars/${player.avatar}`)} alt={player.avatar} />
                                              </div>
                                            )}
                                            {player.name && !player.avatar && (
                                              <div className='symbol symbol-35px symbol-circle'>
                                                <span
                                                  className={`symbol-label bg-light-primary text-primary fw-bold`}
                                                >
                                                  {player.name.charAt(0)}
                                                </span>
                                              </div>
                                            )}
                                          </div>

                                          <div className='ms-5'>
                                            <span
                                              className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                                            >
                                              {player.nickname}
                                            </span>
                                            <div className='fw-bold text-muted'>{player.email}</div>
                                          </div>
                                        </div>

                                        <div className='ms-2'>

                                          <button
                                            type="button"
                                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                          >
                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                                          </button>
                                        </div>
                                      </div>)
                                    })}

                                  </div>
                                </div>)}

                              </>
                            )}
                          />



                        </div>
                      </div>


                      <div data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <h1 className='fw-bolder text-dark mb-3'>Release!</h1>

                          <div className='text-muted fw-bold fs-3 mb-6'>
                            Submit your game to kickstart your tournament.
                          </div>


                          <div className="timeline">

                            <div className="timeline-item">

                              <div className="timeline-line w-40px"></div>

                              <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                                <div className="symbol-label bg-light">
                                  <span className="svg-icon svg-icon-2 svg-icon-gray-500"></span>
                                </div>
                              </div>
                              <div className="timeline-content mb-10 mt-3">
                                <div className="fs-4 fw-bold mb-2">Details:</div>
                                <div className="fs-5 fw-semibold mb-2">
                                  Game: <span className='text-muted'>{props.values.name}</span>
                                </div>
                                <div className="fs-5 fw-semibold">
                                  Platform: <span className='text-muted'>{props.values.platform}</span>
                                </div>
                              </div>

                            </div>


                            {props.values.players && props.values.players.length > 0 && (<div className="timeline-item">

                              <div className="timeline-line w-40px"></div>

                              <div className="timeline-icon symbol symbol-circle symbol-40px">
                                <div className="symbol-label bg-light">

                                  <span className="svg-icon svg-icon-2 svg-icon-gray-500">

                                  </span>

                                </div>
                              </div>

                              <div className="timeline-content mb-0 mt-3">

                                <div className="fs-4 fw-bold mb-2">Players:</div>

                                <div className='mh-300px scroll-y'>
                                  {props.values.players.map((player, index) => {

                                    return (<div
                                      className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'
                                      key={index}
                                    >

                                      <Field className="d-none" name={`players.${index}`} />

                                      <div className='d-flex align-items-center'>
                                        <div className='symbol symbol-35px symbol-circle'>
                                          {player.avatar && (
                                            <div className='symbol-label'>
                                              <img src={toAbsoluteUrl(`/media/svg/avatars/${player.avatar}`)} alt={player.avatar} />
                                            </div>
                                          )}
                                          {player.name && !player.avatar && (
                                            <div className='symbol symbol-35px symbol-circle'>
                                              <span
                                                className={`symbol-label bg-light-primary text-primary fw-bold`}
                                              >
                                                {player.name.charAt(0)}
                                              </span>
                                            </div>
                                          )}
                                        </div>

                                        <div className='ms-5'>
                                          <span
                                            className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                                          >
                                            {player.nickname}
                                          </span>
                                          <div className='fw-bold text-muted'>{player.email}</div>
                                        </div>
                                      </div>
                                    </div>)
                                  })}

                                </div>


                              </div>
                            </div>)}

                            <div className="timeline-item"></div>

                          </div>





                        </div>
                      </div>

                      <div className='d-flex flex-stack pt-10'>
                        <div className='me-2'>
                          <button
                            onClick={prevStep}
                            type='button'
                            className='btn btn-lg btn-light-primary me-3'
                            data-kt-stepper-action='previous'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr063.svg'
                              className='svg-icon-4 me-1'
                            />
                            Back
                          </button>
                        </div>

                        <div>
                          <button type='submit' className='btn btn-lg btn-primary me-3'>
                            <span className='indicator-label'>
                              {stepper.current?.currentStepIndex! - 1 !==
                                stepper.current?.totatStepsNumber! - 1 && 'Continue'}
                              {stepper.current?.currentStepIndex! - 1 ===
                                stepper.current?.totatStepsNumber! - 1 && 'Submit'}

                              {/*stepper.current?.currentStepIndex === undefined ||
                                (stepper.current?.currentStepIndex === undefined && stepper.current?.totatStepsNumber === undefined) ||
                              stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber && 'Continue'*/}
                              {/*stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber && 'Submit'*/}
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-3 ms-2 me-0'
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* begin::Modal Backdrop */}
    <div className='modal-backdrop fade show'></div>
    {/* end::Modal Backdrop */}
  </>)
}

export { CreateGame }

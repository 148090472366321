/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo, createContext } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  ID,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QueryResponseContextProps,
  stringifyRequestQuery,
  WithChildren,
  Response
} from '../../../../../_metronic/helpers'
import { QUERIES } from '../../../../_core/consts'
import { Competition } from '../../../../_core/models/Competition'
import { Tournament } from '../../../../_core/models/Tournament'
import { getCompetitions, getCompetitionsNEW } from '../../../../_core/requests/competition'
import { getLastTournament, getLastTournamentPlayers } from '../../../../_core/requests/user'
import { useQueryRequest } from './QueryRequestProvider'

type ContextProps = {
  tournament?: Response<Tournament>
} & QueryResponseContextProps<Competition>
const QueryResponseContext = createResponseContext<Competition>(initialQueryResponse)
//const QueryResponseContext = createContext<ContextProps>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state, tournament } = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.COMPETITIONS}-${query}`,
    () => {
      return getCompetitionsNEW(query)
    },
    { 
      enabled: !!tournament,
      //enabled: (!!tournament && tournament?.players && tournament?.players.length > 0) ? true : false, 
      //enabled: ((query === updatedQuery && typeof state.filter === 'object' && state.filter !== null && state.filter.hasOwnProperty('game') && state.filter.hasOwnProperty('tournament'))),
      cacheTime: 0, 
      keepPreviousData: true, 
      refetchOnWindowFocus: false }
  )

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponseStatics = () => {
  const { response } = useQueryResponse()
  if (!response) {
    return undefined
  }

  return response?.statics || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const { response } = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
  useQueryResponseStatics
}

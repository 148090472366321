import React, { useEffect } from "react";

export interface ToastProps {
    id: string;
    destroy: () => void;
    title: string;
    content: string;
    duration?: number;
    variant?: 'success' | 'error' | 'warning';
}

const getVariant = (variant: string | undefined) => {

    switch (variant) {
        case 'success':
            return 'bg-light-primary';
        case 'error':
            return 'bg-light-error';
        case 'warning':
            return 'bg-light-warning';

        default:
            return '';
    }
}

const Toast: React.FC<ToastProps> = (props) => {
    const { destroy, content, title, duration = 0, variant } = props;

    useEffect(() => {
        if (!duration) return;

        const timer = setTimeout(() => {
            destroy();
        }, duration);

        return () => clearTimeout(timer);
    }, [destroy, duration]);

    return (
        <div className={`toast bg-light-primary fade show ${getVariant(variant)}`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className={"toast-header"}>
                <strong className="me-auto">{title}</strong>
                <small className="text-muted">just now</small>
                <button type="button" className="btn-close" onClick={destroy}></button>
            </div>
            <div className={"toast-body"}>{content}</div>
        </div>
    );
};

const shouldRerender = (prevProps: ToastProps, nextProps: ToastProps) => {
    return prevProps.id === nextProps.id;
};

export default React.memo(Toast, shouldRerender);
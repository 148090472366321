import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../_metronic/helpers'
import { Competition } from '../models/Competition'

const API_URL = `${process.env.REACT_APP_API_URL}/competitions`

const getCompetitionsNEW = (query: string): Promise<Response<Array<Competition>>> => {
  return axios
    //.get(`${GET_USERS_URL}?${query}`)
    .get(`${API_URL}?${query}`)
    .then((d: AxiosResponse<Response<Array<Competition>>>) => d.data)
}

const getCompetitions = (gameId:ID , tournamentId:ID, query: string): Promise<Response<Array<Competition>>> => {
  return axios
    //.get(`${GET_USERS_URL}?${query}`)
    .get(`${API_URL}?${query}&gameId=${gameId}&tournamentId=${tournamentId}`)
    .then((d: AxiosResponse<Response<Array<Competition>>>) => d.data)
}

const getCompetitionById = (id: ID): Promise<Competition | undefined> => {
  return axios
    .get(`${API_URL}/${id}`)
    .then((response: AxiosResponse<Response<Competition>>) => response.data)
    .then((response: Response<Competition>) => response.data)
}

const createCompetition = (competition: Competition): Promise<Competition | undefined> => {
  return axios
    .post(API_URL, competition)
    .then((response: AxiosResponse<Response<Competition>>) => response.data)
    .then((response: Response<Competition>) => response.data)
}

const updateCompetition = (competition: Competition): Promise<Competition | undefined> => {
  return axios
    .patch(`${API_URL}/${competition._id}`, competition)
    .then((response: AxiosResponse<Response<Competition>>) => response.data)
    .then((response: Response<Competition>) => response.data)
}

const deleteCompetition = (competitionId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/${competitionId}`).then(() => {})
}

const deleteSelectedCompetitions = (competitionIds: Array<ID>): Promise<void> => {
  const requests = competitionIds.map((id) => axios.delete(`${API_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getCompetitionsNEW, getCompetitions, deleteCompetition, deleteSelectedCompetitions, getCompetitionById, createCompetition, updateCompetition}

import { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { KTSVG } from '../../../../../_metronic/helpers'
import { toast } from '../../../../common/toast/ToastManager'
import { QUERIES } from '../../../../_core/consts'
import { deleteGame } from '../../../../_core/requests/games'

import { useListView } from '../core/ListViewProvider'
import { useQueryResponse } from '../core/QueryResponseProvider'

const GamesDeleteModal = () => {
  
  const queryClient = useQueryClient()
  const { setItemIdForDelete, itemIdForDelete } = useListView()
  const { query } = useQueryResponse()

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const closeModal = () => setItemIdForDelete(undefined)

  const deleteItem = useMutation(() => deleteGame(itemIdForDelete), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.GAMES}-${query}`])
      closeModal()

      toast.show({
        title: "Game deleted",
        content: `Game has been successfully deleted. `,
        duration: 6000,
        variant: 'success'
      })
    },
  })

  

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_delete_tournament'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            {/* begin::Modal body */}
            <div className='modal-body m-0 p-0'>
              <div className="alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 m-0">
                <button type="button" className="position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-danger" onClick={closeModal}>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr011.svg'
                    className='svg-icon svg-icon-1'
                  />
                </button>

                <KTSVG
                    path='/media/icons/duotune/general/gen044.svg'
                    className='svg-icon svg-icon-5tx svg-icon-danger mb-5'
                  />

                <div className="text-center">
                  <h5 className="fw-bolder fs-1 mb-5">Delete Tournament</h5>

                  <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>
                  <div className="mb-9">Are you sure you want to <strong>delete</strong> this tournament?</div>
                  <div className="d-flex flex-center flex-wrap">
                    <button type='button' className="btn btn-outline btn-outline-danger btn-active-danger m-2" onClick={closeModal}>No, cancel</button>
                    <button type='button' onClick={async () => await deleteItem.mutateAsync()} className="btn btn-danger m-2">Yes, delete!</button>
                  </div>
                </div>
              </div>
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export { GamesDeleteModal }

/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { Competition } from '../../../../../_core/models/Competition'

type Props = {
  date: string
}

const DateCell: FC<Props> = ({ date }) => {

  const d = new Date(date);

  const _date = d.getDate();
  const _month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
  const _year = d.getFullYear();

  const _hours = d.getHours();
  const _minutes = d.getMinutes();

  return (<div className='d-flex justify-content-end flex-shrink-0'>{_date}/{_month}/{_year} - {_hours}:{_minutes}</div>);
}

export { DateCell }

import {useQueryClient, useMutation} from 'react-query'
import { toast } from '../../../../../common/toast/ToastManager'
import { QUERIES } from '../../../../../_core/consts'
import { deleteSelectedGames } from '../../../../../_core/requests/games'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'

const GamesListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  const deleteSelectedItems = useMutation(() => deleteSelectedGames(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.GAMES}-${query}`])
      clearSelected()
      toast.show({
        title: "Games deleted",
        content: `${selected.length} Games have been successfully deleted. `,
        duration: 6000,
        variant: 'success'
      })
    },
  })

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button
        type='button'
        className='btn btn-danger'
        onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        Delete Selected
      </button>
    </div>
  )
}

export {GamesListGrouping}

import axios, { AxiosResponse } from 'axios'
import { Response } from '../../../_metronic/helpers'
import { Stats } from '../models/Competition'
import { Friend } from '../models/Friend'
import { Game } from '../models/Game'
import { Player } from '../models/Player'
import { Tournament } from '../models/Tournament'

const API_URL = `${process.env.REACT_APP_API_URL}/user`

const getGames = (query: string): Promise<Response<Array<Game>>> => {
  return axios
    //.get(`${GET_USERS_URL}?${query}`)
    .get(`${API_URL}/games?${query}`)
    .then((d: AxiosResponse<Response<Array<Game>>>) => d.data)
}

const getFriends = (query: string): Promise<Response<Array<Friend>>> => {
  return axios
    //.get(`${GET_USERS_URL}?${query}`)
    .get(`${API_URL}/friends?${query}`)
    .then((d: AxiosResponse<Response<Array<Friend>>>) => d.data)
}

const getStats = (): Promise<Response<Stats>> => {
  return axios
    .get(`${API_URL}/stats`)
    .then((d: AxiosResponse<Response<Stats>>) => d.data)
}

const getLastTournament = (query: string): Promise<Response<Array<Game>>> => {
  return axios
    //.get(`${GET_USERS_URL}?${query}`)
    .get(`${API_URL}/tournament?${query}`)
    .then((d: AxiosResponse<Response<Array<Game>>>) => d.data)
}

const getLastTournamentPlayers = (): Promise<Response<Tournament>> => {
  return axios
    //.get(`${GET_USERS_URL}?${query}`)
    .get(`${API_URL}/tournament`)
    .then((d: AxiosResponse<Response<Tournament>>) => d.data)
}

export { getGames, getFriends, getStats, getLastTournament, getLastTournamentPlayers }

import React, { FC, useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, Form, Formik, FormikValues } from 'formik'
import { isNotEmpty, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'
import { CompetitionsListLoading } from '../components/loading/CompetitionsListLoading'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { toast } from '../../../../common/toast/ToastManager'
import { Competition } from '../../../../_core/models/Competition'
import { createCompetition, updateCompetition } from '../../../../_core/requests/competition'

type Props = {
  isCompetitionLoading: boolean
  competition: Competition
}

const initialCompetition: Competition = {
  game: '',
  tournament: '',
  scores: [],
}

const editCompetitionSchema = Yup.object().shape({
  game: Yup.string()
    .min(16, 'Minimum 3 symbols')
    .max(24, 'Maximum 50 symbols')
    .required('Game is required'),
  scores: Yup.array().of(
    Yup.object().shape({
      player: Yup.string(),
      points: Yup.number()
    })
  ),
  winner: Yup.string().required('Winner is required'),
  
})

const CompetitionEditModalForm: FC<Props> = ({ competition, isCompetitionLoading }) => {
  const { setItemIdForUpdate, tournament, players } = useListView()
  const { refetch } = useQueryResponse()

  //const players = tournament?.players

  const [competitionForEdit] = useState<Competition>({
    ...competition,
    game: competition.game || tournament?.game,
    tournament: competition.tournament || tournament?._id,
    winner: competition.winner || undefined,
    score: competition.scores || tournament?.players?.map(player => ({ player: player._id, points: 0 }))
    //scores: tournament?.players?.map(player => ({ player: player._id, points: competition.scores?.find(score=>score.player === player._id)?.points || 0 })) || tournament?.players?.map(player => ({ player: player._id, points: 0 })),
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const handleSubmit = async (values: any, { setSubmitting }: FormikValues) => {

    setSubmitting(true)
    try {
      if (isNotEmpty(values._id)) {
        await updateCompetition(values)
      } else {
        await createCompetition(values)
      }

      const action = isNotEmpty(values._id) ? 'updated' : 'created'
      toast.show({
        title: `Competition ${action}`,
        content: `Competition ${values._id} has been successfully ${action}. `,
        duration: 6000,
        variant: 'success'
      })

      setSubmitting(true)
      cancel(true)

    } catch (ex: any) {
      if (ex.response.status == 400) {
        // Object.entries(ex.response.data.payload.errors).map(([key, val]) => setFieldError(key, val as string));
      }
    } finally {
      //setSubmitting(true)
      //cancel(true)

    }
  }


  return (
    <Formik
      initialValues={competitionForEdit}
      validationSchema={editCompetitionSchema}
      onSubmit={handleSubmit}
    >
      {props => (
        <Form className='form' noValidate id='kt_modal_add_competition_form'>
          {JSON.stringify(props)}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 rounded-start'>Played</th>
                  <th>Score</th>
                  <th className='text-end rounded-end pe-4'>Winner</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {tournament?.players?.map((player, index) => {
                  
                  return (<tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>

                        <div className="form-check form-switch form-check-custom form-check-solid me-10">
                          <input
                            className="form-check-input h-30px w-50px"
                            type="checkbox"
                            name={`scores[${index}][player]`}
                            value={player?._id as string}
                            checked={props.values.scores?.map((e) => e.player).includes(player?._id)}
                            disabled={props.isSubmitting || isCompetitionLoading}
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                props.setFieldValue(`scores.${index}.player`, player?._id);
                                //console.log(values.permission);
                              } else {
                                props.setFieldValue(`scores.${index}.player`, false);
                                props.setFieldValue(`scores.${index}.points`, 0);
                                if(props.values.winner === player?._id) {
                                  props.setFieldValue('winner', undefined);
                                }

                              }
                            }}
                          />

                        </div>


                        <div className='symbol symbol-35px symbol-circle'>
                          {player?.avatar && <img alt='Pic' src={toAbsoluteUrl(player.avatar)} />}
                          {player?.name && (
                            <div className='symbol symbol-35px symbol-circle'>
                              <span
                                className={`symbol-label bg-light-primary text-primary fw-bold`}
                              >
                                {player?.name.charAt(0)}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className='ms-5'>
                          <a
                            href='#'
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary mb-2'
                          >
                            {player?.nickname}
                          </a>
                          <div className='fw-bold text-muted'>{player?.email}</div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <Field
                        className="form-control form-control-solid"
                        type="number" name={`scores.${index}.points`}
                        min={0}
                        disabled={/*(!props.values.scores![index].player) ||*/ (props.isSubmitting || isCompetitionLoading)}
                      />
                    </td>


                    <td>
                      <div className="float-end form-check form-check-custom form-check-solid form-check-lg">
                        <Field
                          className="form-check-input"
                          name='winner'
                          type="radio"
                          value={player?._id}
                          disabled={/*(!props.values.scores![index].player) || */(props.isSubmitting || isCompetitionLoading)}
                        />

                      </div>
                    </td>
                  </tr>)
                })}
              </tbody>



              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>

          <ErrorMessage name='scores' component="div" className="invalid-feedback d-block" />
          <ErrorMessage name='winner' component="div" className="invalid-feedback d-block" />

          {/* begin::Actions */}
          <div className='text-center pt-15'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-competitions-modal-action='cancel'
              disabled={props.isSubmitting || isCompetitionLoading}
            >
              Discard
            </button>

            <button
              type='submit'
              className='btn btn-primary'
              data-kt-competitions-modal-action='submit'
              disabled={isCompetitionLoading || props.isSubmitting || !props.isValid || !props.touched}
            >
              <span className='indicator-label'>Submit</span>
              {(props.isSubmitting || isCompetitionLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {(props.isSubmitting || isCompetitionLoading) && <CompetitionsListLoading />}
        </Form>)
      }
    </Formik >)

}

export { CompetitionEditModalForm }

/* eslint-disable react-hooks/exhaustive-deps */
import { FC, createContext, useContext, useState } from 'react'
import { WithChildren } from '../../../../_metronic/helpers'
import { CreateGame } from '../../partials';

export enum ModalTypes {
  CREATE_GAME_MODAL = "CREATE_GAME_MODAL",
  //DELETE_MODAL = "DELETE_MODAL",
  //UPDATE_MODAL = "UPDATE_MODAL"
}

const MODAL_COMPONENTS = {
  [ModalTypes.CREATE_GAME_MODAL]: CreateGame,
  //[ModalTypes.DELETE_MODAL]: DeleteModal,
  //[ModalTypes.UPDATE_MODAL]: UpdateModal
};

export interface ModalsManagementContextModel {
  showModal: (_modalType: ModalTypes, _modalProps?: any) => void
  hideModal: () => void
}

const ModalsManagementContext = createContext<ModalsManagementContextModel>({
  showModal: (_modalType: ModalTypes, _modalProps?: any) => { },
  hideModal: () => { },
})

const ModalsManagementProvider: FC<WithChildren> = ({ children }) => {
  const [store, setStore] = useState<{ modalType: ModalTypes | null, modalProps?: any }>({ modalType: null });
  const { modalType, modalProps } = store || {};

  const showModal = (modalType: ModalTypes, modalProps: any = {}) => {

    setStore({
      ...store,
      modalType,
      modalProps,
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {},
    });
  };

  const renderComponent = () => {
    const ModalComponent = modalType ? MODAL_COMPONENTS[modalType] : undefined;
    if (!ModalComponent) {
      return null;
    }
    return <ModalComponent id="global-modal" {...modalProps} />;
  };

  const value: ModalsManagementContextModel = {
    showModal,
    hideModal
  }

  return (<ModalsManagementContext.Provider value={value}>
    {renderComponent()}
    {children}
  </ModalsManagementContext.Provider>)
}

function useModalsManagement() {
  return useContext(ModalsManagementContext)
}


export { ModalsManagementProvider, useModalsManagement }

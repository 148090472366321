/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { useThemeMode } from '../../../layout/theme-mode/ThemeModeProvider'
import { getCSSVariableValue } from '../../../../../../_metronic/assets/ts/_utils'
import { useFetch } from './useFetch'
import { StatsList } from './components/StatsList'
import { Stats } from '../../../../models/Competition'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const init: Stats = {
  totalGames: 0,
  totalTournaments: 0,
  totalCompetitions: 0,
  totalPoints: 0,
  totalVictories: 0,
  avg: {
    points: 0,
    victories: 0
  }
}

const PlayerGraphStatsWidget: React.FC<Props> = ({ className, chartColor, chartHeight }) => {

  const { loading, data } = useFetch()

  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()


  useEffect(() => {

    const refreshChart = () => {
      if (!chartRef.current) {
        return
      }
      const chart = new ApexCharts(chartRef.current, getChartOptions(chartHeight, data?.totalGames, data?.totalVictories))
      if (chart) {
        chart.render()
      }

      return chart
    }

    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, data, chartHeight])

  

  return (<div className={`card ${className}`}>
    {/* begin::Header  */}
    <div className={`card-header border-0 bg-${chartColor} py-5`}>
      <h3 className='card-title fw-bold text-white'>Statistics</h3>
    </div>
    {/* end::Header  */}

    {/* begin::Body  */}
    <div className='card-body p-0'>
      {/* begin::Chart  */}
      {data && (data.totalCompetitions && data.totalCompetitions > 0) && (
        <div
          ref={chartRef}
          className={`mixed-widget-12-chart bg-${chartColor}`}
        ></div>
      )}
      {/* end::Chart  */}

      {/* begin::Stats  */}
      <div className='card-rounded mt-n10 position-relative card-px pt-15 pb-6'>

        <StatsList data={data || init} />

      </div>
      {/* end::Stats  */}
    </div>
    {/* end::Body  */}
  </div>)
}

export { PlayerGraphStatsWidget }

function getChartOptions(chartHeight: string, totalGames = 0, victories = 0): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')

  /*const primaryColor = getCSSVariableValue('--kt-primary')
  const primaryLightColor = getCSSVariableValue('--kt-primary-light')
  const secondaryColor = getCSSVariableValue('--kt-secondary')
  const warningColor = getCSSVariableValue('--kt-warning')
  const infoColor = getCSSVariableValue('--kt-info')*/

  return {
    series: [
      {
        name: "Total",
        data: [totalGames, victories]
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
        distributed: true
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Total Games', 'Victories'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        }
      },
    },
    yaxis: {
      min: 0,
      max: totalGames,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      type: ['solid', 'solid'],
      opacity: [0.25, 1],
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '' + val + ''
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ['#ffffff', '#ffffff'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 20,
        right: 20,
      },
    },
  }
}
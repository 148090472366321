/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { MenuComponent } from '../../../../../../_metronic/assets/ts/components'
import { ID, KTSVG } from '../../../../../../_metronic/helpers'
import { toast } from '../../../../../common/toast/ToastManager'
import { QUERIES } from '../../../../../_core/consts'
import { deleteCompetition } from '../../../../../_core/requests/competition'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'

type Props = {
  id: ID
}

const CompetitionActionsCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate } = useListView()
  const { query, refetch } = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const deleteItem = useMutation(() => deleteCompetition(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      //queryClient.invalidateQueries([`${QUERIES.COMPETITIONS}-${query}`])
      refetch()
      toast.show({
        title: "Competition deleted",
        content: `Competition has been successfully deleted. `,
        duration: 6000,
        variant: 'success'
      })
    },
  })

  return (
      <div className='d-flex justify-content-end flex-shrink-0'>
        
        <button
          onClick={openEditModal}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </button>
        <button
          onClick={async () => await deleteItem.mutateAsync()}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          data-kt-competitions-table-filter='delete_row'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen027.svg'
            className='svg-icon-3'
          />
        </button>
      </div>
  )
}

export { CompetitionActionsCell }

import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { CompetitionsListHeader } from './components/header/CompetitionsListHeader'
import { CompetitionsTable } from './table/CompetitionsTable'
import { CompetitionEditModal } from './competition-edit-modal/CompetitionEditModal'
import { KTCard } from '../../../../_metronic/helpers'
import { FC } from 'react'
import { Tournament } from '../../../_core/models/Tournament'
import { Game } from '../../../_core/models/Game'
import { CompetitionsListPagination } from './components/pagination/CompetitionsListPagination'
import { useQuery } from 'react-query'
import { getLastTournamentPlayers } from '../../../_core/requests/user'
import { QUERIES } from '../../../_core/consts'

const Competitions = () => {
  const { itemIdForUpdate } = useListView()
  return (
    <>
      <KTCard stretch='stretch'>
        <CompetitionsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <CompetitionEditModal />}
    </>
  )
}

const CompetitionsWrapper: FC = () => {

  const {
    refetch,
    isFetching,
    data: response,
  } = useQuery(
    `${QUERIES.USER}-last-tournament`,
    () => {
      return getLastTournamentPlayers()
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  if(isFetching) return (<>Loading</>)

  if(!response?.data) return (<></>)

  return (
    <QueryRequestProvider tournament={response?.data} reload={refetch}>
      <QueryResponseProvider>
        <ListViewProvider>
          <Competitions />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export { CompetitionsWrapper }

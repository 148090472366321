import {Column} from 'react-table'
import {GameInfoCell} from './GameInfoCell'
import {GameActionsCell} from './GameActionsCell'
import {GameSelectionCell} from './GameSelectionCell'
import {GameCustomHeader} from './GameCustomHeader'
import {GameSelectionHeader} from './GameSelectionHeader'
import { Game } from '../../../../../_core/models/Game'
import { DateCell } from './DateCell'
import { GamePlayersCell } from './GamePlayersCell'

const gamesColumns: ReadonlyArray<Column<Game>> = [
  {
    Header: (props) => <GameSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <GameSelectionCell id={props.data[props.row.index]._id} />,
  },
  {
    Header: (props) => <GameCustomHeader tableProps={props} title='Game' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <GameInfoCell game={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <GameCustomHeader tableProps={props} title='Players' />,
    id: 'players',
    Cell: ({...props}) => <GamePlayersCell game={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <GameCustomHeader tableProps={props} title='Updated date' className='min-w-125px' />
    ),
    id: 'updatedAt',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].updatedAt} />,
  },
  {
    Header: (props) => (
      <GameCustomHeader tableProps={props} title='Created date' className='min-w-125px' />
    ),
    id: 'createdAt',
    Cell: ({...props}) => <DateCell date={props.data[props.row.index].createdAt} />,
  },
  {
    Header: (props) => (
      <GameCustomHeader tableProps={props} title='Admin' className='min-w-125px' />
    ),
    id: 'createdBy',
    Cell: ({...props}) => props.data[props.row.index].createdBy.name,
  },
  {
    Header: (props) => (
      <GameCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <GameActionsCell id={props.data[props.row.index]._id} />,
  },
]

export {gamesColumns}

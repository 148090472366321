import { FC, useState, createContext, useContext, useMemo } from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  initialListView,
  ListViewContextProps,
  groupingOnSelectAll,
  WithChildren,
} from '../../../../../_metronic/helpers'
import { Game } from '../../../../_core/models/Game'
import { useAuth } from '../../../auth'
import { useQueryResponse, useQueryResponseData } from './QueryResponseProvider'

type CustomListViewContextProps = {
  editableData: Game[]
} & ListViewContextProps

const initial = { ...initialListView, editableData: []}
const ListViewContext = createContext<CustomListViewContextProps>(initial)

const ListViewProvider: FC<WithChildren> = ({ children }) => {
  const [selected, setSelected] = useState<Array<ID>>(initial.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initial.itemIdForUpdate)
  const { isLoading } = useQueryResponse()
  const data = useQueryResponseData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const { currentUser } = useAuth()
  const editableData = useMemo(() => data.filter(game => game.createdBy?._id === currentUser?.player._id), [data, currentUser])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(editableData, selected), [data, selected])
  const [itemIdForDelete, setItemIdForDelete] = useState<ID>(initialListView.itemIdForDelete)
  
  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        disabled,
        isAllSelected,
        editableData,
        onSelect: (id: ID) => {
          const game = editableData.find(game => game._id === id)
          if(game?.createdBy?._id === currentUser?.player._id) {
            groupingOnSelect(id, selected, setSelected)
          }
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, editableData)
        },
        clearSelected: () => {
          setSelected([])
        },
        itemIdForDelete,
        setItemIdForDelete
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export { ListViewProvider, useListView }
